//@ts-ignore
import AuditReport from '../documents/audit_report.pdf';
//@ts-ignore
import WithdrawalForm from '../documents/withdrawal_form.pdf';

export const constants = {
    whitePaper: "https://moongaming.notion.site/Medieval-Empires-Whitepaper-38ed57c1b437410eae3c193af035a931/",
    faq: "https://faq.medievalempires.com/",
    discord: "https://discord.com/invite/ZhRYszk3qF",
    twitter: "https://x.com/MedievalEmpires",
    instagram: "https://www.instagram.com/medievalempirescom/",
    telegram: "https://t.me/Medievalempireschat",
    facebook: "https://www.facebook.com/medievalempirescom",
    youtube: "https://www.youtube.com/channel/UCSFO9rt_lG5nApw645ws5uQ",
    linkedIn: "https://www.linkedin.com/company/moon-gaming/",
    youtube_intro: "https://www.youtube.com/embed/4tqNaJZaegQ?si=Qvj5hkhWlRfpg6Jy&rel=0&controls=1&autoplay=0&mute=0&start=0",
    windows_client: "https://download.apps.medievalempires.com/windows/MedievalEmpiresSetup.exe",
    mac_client: "https://download.apps.medievalempires.com/macos/MedievalEmpires.dmg",
    hyperplay_download: "https://store.hyperplay.xyz/game/medievalempires",
    ultraplay_download: "https://store.ultra.io/en/store/games/medieval_empires/665870fd630cd938414ec32d?utm_source=affiliates&utm_medium=medievalempires&utm_campaign=medievalempires",
    open_sea: "https://opensea.io/collection/medieval-empires-lands",
    help_shift: "https://medievalempires.helpshift.com/",
    staking: "https://meeclaiming.dev.apps.medievalempires.com/",
    bybit: "https://partner.bybit.com/b/MEEBybit",
    mexc: "https://promote.mexc.com/a/SpotMEE",
    quickSwap: "https://quickswap.exchange/#/swap?currency0=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&currency1=0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D&swapIndex=0",
    imx_about: "https://www.immutable.com/blog/immutable-passport-the-ultimate-gaming-wallet",
    imx_passport_about: "https://www.immutable.com/blog/immutable-passport",
    loyality_platform_link: "https://loyalty.medievalempires.com",
    launchpad_link: "https://launchpad.medievalempires.com",
    protected_pages_password: "istanbul",
    audit_report: AuditReport,
    withdrawal_form: WithdrawalForm,
    app_routes: {
        community: "/#community",
        account: "/account",
        home: "/",
        mee_token: "/mee-token",
        team: "/core-team",
        terms_and_conditions: "/terms-and-conditions",
        staking_vip_terms_and_condition: "/staking-terms-and-conditions-vip",
        right_of_withdrawal: "/right-of-withdrawal-policy",
        tokenomics: "/tokenomics",
        news: "/news",
        privacy_policy: "/privacy-policy",
        login: "/?login=1",
        staking_vip: "/staking/vip",
        marketplace: {
            home: "/marketplace",
            purchase: "/marketplace/purchase",
            collections: "/marketplace/collections"
        },
    }
}

export const cmsCategoryMetadata: any = {
    "updates": {
        name: "Updates",
        color: "#f0a7a7",
        fontColor: "#443c2e"
    },
    "art-updates": {
        name: "Art Updates",
        color: "#2a2e31",
        fontColor: "#dead80"
    },
    "miscellaneous": {
        name: "Miscellaneous",
        color: "#dae78e",
        fontColor: "#443c2e"
    },
    "announcements": {
        name: "Announcements",
        color: "#e04220",
        fontColor: "#ffffff"
    },
    "gameplay-updates": {
        name: "Gameplay updates",
        color: "#fee9c7",
        fontColor: "#443c2e"
    },
    "interviews": {
        name: "Interviews",
        color: "#7cf9e2",
        fontColor: "#443c2e"
    },
    "technology-updates": {
        name: "Technology Updates",
        color: "#2473e9",
        fontColor: "#ffffff"
    },
    "events": {
        name: "Events",
        color: "#a551d9",
        fontColor: "#ffffff"
    },
}